import React from "react";
import "./pages.css";
import Breadcrumbs from "../componentBreadCrumps/Breadcrumbs";
import "bootstrap/dist/css/bootstrap.min.css";
import ConditionComp from "../componentCondition/Condition";
import sortPrice from "../../price/testoviypricelist.xlsx";
import sortprokat from "../../images/rolledMetalSort/sortprokat.jpg";
import BannerInfo from "../componentBannerInfoForPages/BannerInfo";
import SalesBlock from "../componentSales/salesBlock";
import InfoBlock from "../componentInfoBlock/InfoBlock";
import YandexMap from '../componentYandexMap/yandexMap';
import { Helmet } from "react-helmet";


const items = [
  { name: "Арматура" },
  { name: "Арматурные каркасы" },
  { name: "Балка (Двутавр)" },
  { name: "Катанка" },
  { name: "Квадрат г/к" },
  { name: "Круг г/к" },
  { name: "Полоса г/к" },
  { name: "Рельс" },
  { name: "Уголок" },
  { name: "Швеллер" },
  { name: "Шестигранник металлический" },
  { name: "Шпунт Ларсена" },
];

const SortTitle = "Сортовой прокат оптом с доставкой";
const SortText = "ООО «ММГК» предлагает купить сортовой прокат по оптовой цене. Сортамент включает в себя различные изделия: от простых стальных листов и балок до сложных профильных труб и арматуры. Наш металлопрокат позволяет удовлетворить потребности клиентов из различных отраслей промышленности, включая строительство, машиностроение, производство и другие."
const SortText2 = "Мы поставляем продукцию напрямую с заводов EVRAZ, «Магнитогорский Металлургический Комбинат», «Северсталь», «УралТрубПром», МЕЧЕЛ, ВМК «Красный октябрь». За счет этого готовы выполнить особо крупные заказы в сжатые сроки."

function SortovoiProkat() {
  return (
    <div className="container-fluid p-0">
      <Helmet>
        <title>Сортовой прокат</title>
      </Helmet>
      <BannerInfo img="backgroundImages/sortovoiProkat.png" title="Сортовой прокат"></BannerInfo>
      <div className="container-fluid p-0 pb-5 bg-light bg-gradient">
        <div className="container">
          <Breadcrumbs />
          <h2>Сортовой прокат</h2>
          <div className="row">
            {items.map((item, index) => (
              <div className="col-md-3 col-6 my-2 user-select-none" key={index}>
                <div
                  className="card bg-secondary text-white"
                  style={{ height: "100px" }}
                >
                  <div className="card-body d-flex align-items-center justify-content-center">
                    <h5 className="card-title text-center">{item.name}</h5>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
      <div className="text-center">
        {/* <a href={sortPrice} download="ТестПрайсЛистСпиздилСКакоготоСайта.xlsx">
          <button className="btn btn-warning">Скачать прайс-лист</button>
        </a> */}
      </div>
      <ConditionComp imgSrc={sortprokat}></ConditionComp>
      <SalesBlock></SalesBlock>
      <InfoBlock title={SortTitle} img="/sortImg.webp" text={SortText} text2={SortText2}></InfoBlock>
      <YandexMap></YandexMap>
    </div>
  );
}

export default SortovoiProkat;
