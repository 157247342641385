import BannerInfo from "../componentBannerInfoForPages/BannerInfo";
import { Helmet } from "react-helmet";

function NotFoundPage() {
    return (
      <div>
        <Helmet>
          <title>Такой страницы не существует :(</title>
        </Helmet>
 <BannerInfo img="backgroundImages/backgroundMMETGK.webp" title="Страница не найдена" text="Такой страницы не существует" showButton={false}>
 </BannerInfo>
      </div>
     
    );
  };

export default NotFoundPage;