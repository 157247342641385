import Navbar from "../a_navbar/Navbar"


function NerjaveiushiyProkat() {
    return (
        <div className="container">
          <h2>Нержавеющий прокат</h2>

        </div>
    );
  };

export default NerjaveiushiyProkat;