import "./ourProducts.css"
import items from "./items";
import { NavLink } from 'react-router-dom';

function OurProducts() {
    return (
      <div className="container ">
        <h2>Наша продукция</h2>
        <div className="d-flex row justify-content-start">
          {items.map((item) => (
            <NavLink to={item.path} key={item.id} className="card col-sm-4 p-2 g-2 card__product">
              <div className="card__body">
                <img src={item.imgSrc} loading="lazy" className="card-img img-fluid" alt={item.title} />
                {/* <div className="card-img-overlay"> */}
                <div className="hover__container">
                  <h5 className="card-title">{item.title}</h5>
                  {/* <p>{item.description}</p>
                  <button className="btn text-white">Подробнее</button> */}
                </div>
              </div>
            </NavLink>
          ))}
        </div>
      </div>
    );
  };
  
  export default OurProducts; 