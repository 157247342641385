import Breadcrumbs from "../componentBreadCrumps/Breadcrumbs";
import blockImage from "../../images/aboutPageBlockImage.webp"
import QualityPNG from "../../images/advantages/quality.png";
import SpeedPNG from "../../images/advantages/accelerate.png";
import CarePNG from "../../images/advantages/care.png";
import YandexMap from "../componentYandexMap/yandexMap";
import "./pages.css"
import { Helmet } from "react-helmet";
function CompanyPage() {
    return (
      <div className="container-fluid p-0">
        <Helmet>
          <title>О компании</title>
        </Helmet>
        <div id="about__company__background"></div>
        <div className="container">
          <Breadcrumbs></Breadcrumbs>
          <h2>О компании</h2>
          <div className="row my-4">
            <div className="col-12 col-sm-12 col-md-6 fs-5 shadow p-5">
              <p>ООО «Многопрофильная металлургическая группа компаний» производит и поставляет по запросу потребителя во все регионы страны металлопрокат сортовой, трубный, листовой, цветные металлы, нержавеющую сталь, ферросплавы. Продукция отличается высокими эксплуатационными качествами и закрывает потребности предприятий различных отраслей промышленности.</p>
              <p>Широкий ассортимент металлопродукции в постоянном наличии дает возможность покупать оптом все материалы. Постоянным клиентам и крупным оптовикам продукция отпускается по индивидуальным ценам.</p>
            </div>
            <div className="col-12 col-sm-12 col-md-6">
              <img className="img-fluid h-100 right__image" src={blockImage}></img>
            </div>
          </div>

          <h2 className="my-5 text-center">Металлопрокат всех видов по <span className="text-warning">выгодным</span>  ценам</h2>
          <div className="fs-5">
            <p>Номенклатура каталога постоянно расширяется, однако, компания не ограничивается товарами из наличия и готова к поставкам нужных позиций под заказ. Именно индивидуальный подход и внимательность к пожеланиям заказчиков вывели «Многопрофильную металлургическую группу компаний» в лидеры региона и позволили стать стратегическим партнером для ряда крупных предприятий страны.</p>
            <p>Ключевые ценности компании остаются неизменными со дня ее основания. Мы всегда открыты для честного диалога, основанного на балансе интересов и поиске компромиссов.</p>
          </div>
          <h2 className="my-5 text-center"><span className="text-warning">Три</span> ключевых принципа компании</h2>
          <div className="row mb-5">
            <div className="col-sm-4 my-2">
              <div className="card border-warning h-100 align-items-center p-5">
              <img src={QualityPNG} className="card-img-top w-25 p-2 rounded-circle bg-warning" alt="..."/>
                <div className="card-body text-center">
                  <h4 className="card-title">Качество</h4>
                  <p className="card-text fs-5">Доверяем и поставляем только проверенное качественное сырье</p>
                </div>
              </div>
            </div>
            <div className="col-sm-4 my-2">
              <div className="card border-warning h-100 align-items-center p-5">
              <img src={SpeedPNG} className="card-img-top w-25 p-2 rounded-circle bg-warning" alt="..."/>
                <div className="card-body text-center">
                  <h4 className="card-title">Скорость</h4>
                  <p className="card-text fs-5">Мы ценим ваше время и всегда отгружаем заказы в минимальные сроки</p>
                </div>
              </div>
            </div>
            <div className="col-sm-4 my-2">
              <div className="card border-warning h-100 align-items-center p-5">
              <img src={CarePNG} className="card-img-top w-25 p-2 rounded-circle bg-warning" alt="..."/>
                <div className="card-body text-center">
                  <h4 className="card-title">Забота</h4>
                  <p className="card-text fs-5">Делаем все возможное для сотрудничества на индивидуальных условиях</p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <YandexMap></YandexMap>
      </div>
    );
  }
  
  export default CompanyPage;