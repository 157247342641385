import BackgroundBanner from '../componentBackgroundBanner/BackgroundBanner';
import OurProducts from '../componentOurProducts/OurProducts';
import SalesBlock from '../componentSales/salesBlock';
import PreFooter from '../componentPreFooter/preFooter';
import CooperationTerms from '../componentCooperationTerms/cooperationTerms';
import CarouselComponent from '../componentCarouselDealers/CarouselComponent'; // убедитесь в правильности пути
import YandexMap from '../componentYandexMap/yandexMap';
import { Helmet } from 'react-helmet';
function HomePage() {
    return (
      <div>
        <Helmet>
          <title>Главная страница</title>
        </Helmet>
        <BackgroundBanner></BackgroundBanner>
        <OurProducts></OurProducts>
        <CooperationTerms></CooperationTerms>
        <CarouselComponent></CarouselComponent>
        <SalesBlock></SalesBlock>
        <PreFooter></PreFooter>
        <YandexMap></YandexMap>
      </div>
    );
  }
  
  export default HomePage;