import BannerInfo from "../componentBannerInfoForPages/BannerInfo";
import Breadcrumbs from "../componentBreadCrumps/Breadcrumbs";
import sortPrice from "../../price/testoviypricelist.xlsx";
import ConditionComp from "../componentCondition/Condition";
import SalesBlock from "../componentSales/salesBlock";
import InfoBlock from "../componentInfoBlock/InfoBlock";
import YandexMap from "../componentYandexMap/yandexMap";
import { Helmet } from "react-helmet";
const items = [
  { name: "Бесшовные" },
  { name: "Профильные" },
  { name: "Толстостенные" },
  { name: "Больших диаметров" },
  { name: "Электросварные" },
  { name: "Оцинкованные" },
  { name: "Нержавеющие" },
  { name: "Винтовые сваи" },
];

const TrubTitle = "Металлические трубы оптом с быстрой доставкой";
const TrubText =
  "ООО «ММГК» предлагает купить недорого металлические трубы по цене от производителя. Мы работаем с надежными транспортными компаниями, такими как: «ЖелДорЭкспедиция», «Байкал Сервис», «Деловые Линии» и ПЭК, чтобы гарантировать быструю и безопасную доставку заказов в любой регион. Доставка возможна в любое удобное для вас место, включая склад, строительный объект или производство.2";
const TrubText2 =
  "По запросу оказываем помощь в погрузке и разгрузке материалов, чтобы обеспечить максимальный комфорт нашим клиентам. Команда профессионалов всегда готова помочь вам с выбором трубного проката и расчетом стоимости.";

function TrubniyProkat() {
  return (
    <div className="container-fluid p-0">
      <Helmet>
        <title>Трубный прокат</title>
      </Helmet>
      <BannerInfo
        img="backgroundImages/backgroundMMETGK.webp"
        title="Трубный прокат"
      ></BannerInfo>
      <div className="container-fluid p-0 pb-5 bg-light">
        <div className="container">
          <Breadcrumbs />
          <h2>Трубный прокат</h2>
          <div className="row">
            {items.map((item, index) => (
              <div className="col-md-3 col-6 my-2 user-select-none" key={index}>
                <div
                  className="card bg-secondary text-white"
                  style={{ height: "100px" }}
                >
                  <div className="card-body d-flex align-items-center justify-content-center">
                    <h5 className="card-title text-center">{item.name}</h5>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
        
      </div>
      <div className="text-center">
          {/* <a
            href={sortPrice}
            download="ТестПрайсЛистСпиздилСКакоготоСайта.xlsx"
          >
            <button className="btn btn-warning">Скачать прайс-лист</button>
          </a> */}
        </div>
      <ConditionComp imgSrc="sortImg.webp"></ConditionComp>
        <SalesBlock></SalesBlock>
        <InfoBlock title={TrubTitle} img="/sortImg.webp" text={TrubText} text2={TrubText2}></InfoBlock>
        <YandexMap></YandexMap>
    </div>
  );
}

export default TrubniyProkat;
