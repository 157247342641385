import './salesBlock.css';
import SmallImage from "./small__image__sales.png";
import SendMailBTN from '../sendMailBTN/SendMailBTN';

function SalesBlock() {
    return (
      <div className='container-fluid py-5 my-5 position-relative' id='sales'>
        <div className='container pt-5'>

            <h2>Специальные предложения</h2>
            <p>Всегда есть выгодные условия</p>
            <div className='row align-items-center '>
              <div className='col-md-5 col-sm-12'>
                <img src={SmallImage} loading="lazy" width={100+"%"}></img>
              </div>
              <div className='col-md-7 col-sm-12'>
                <h2></h2>
                <p>Наш металлопрокат соответствует всем стандартам и требованиям, обеспечивая надежность и долговечность
                   ваших проектов, получите персональную цену при заказе металлопроката объемом от 5 тонн</p>
                   <SendMailBTN textBTN="Заказать"></SendMailBTN>
              </div>
            </div>
        </div>
        <div className="running-line"></div>
      </div>
    );
  }
  
  export default SalesBlock;
  