import { useState, useEffect } from 'react';

const BackToTop = () => {
  const [isVisible, setIsVisible] = useState(false);
  const [isHovered, setIsHovered] = useState(false);
  // Функция для отслеживания прокрутки
  const toggleVisibility = () => {
    if (window.pageYOffset > 50) {
      setIsVisible(true);
    } else {
      setIsVisible(false);
    }
  };

  // Функция для прокрутки наверх
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth', // Плавная прокрутка
    });
  };

  useEffect(() => {
    window.addEventListener('scroll', toggleVisibility);

    // Очистка обработчика события при размонтировании компонента
    return () => {
      window.removeEventListener('scroll', toggleVisibility);
    };
  }, []);

  // Стили для кнопки
  const buttonStyle = {
    position: 'fixed',
    right: '30px',
    padding: '10px 15px',
    fontSize: '18px',
    color: 'black',
    border: 'none',
    cursor: 'pointer',
    opacity: isHovered ? '1' : '0.7',
    transition: 'opacity 0.4s, bottom 0.6s',
    zIndex: 1000,
    bottom: isVisible ? '50px' : '-60px', // Позиция в зависимости от isVisible
  };

  const buttonHoverStyle = {
    opacity: '1',
  };

  return (
    <>
      {isVisible && (
        <button
          onClick={scrollToTop}
          style={buttonStyle}
          onMouseOver={e => (e.currentTarget.style.opacity = '1')}
          onMouseOut={e => (e.currentTarget.style.opacity = '0.7')}
          aria-label="Наверх"
          className='btn btn-warning scroll-to-top'
        >
          ↑
        </button>
      )}
    </>
  );
};

export default BackToTop;