import React, { useState, useEffect } from 'react';
import InfoBlockAdvantage from '../componentInfoBlockAdv/ComponentBlockAdv';


const InfoBlock = ({title, img, text, text2}) => {
    const [isMobile, setIsMobile] = useState(window.innerWidth < 768);

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth < 768);
        };
        
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    return (
      <div className="container">
        <h2 className="text-center">{title}</h2>
        <div className="d-flex row my-5">
        <div className="col-4" style={{display: isMobile ? "none" : "block"}}>
                <img src={img} alt={title} className='img-fluid h-100 shadow' ></img>
            </div>
        <div className="col-12 col-md-8 fs-5" style={{paddingLeft: isMobile ? "12px" : "12px", textAlign: isMobile ? "justify" : "start"}}>
                <p>{text}</p>
                <p>{text2}</p>
            </div>
            
             
        </div>
        <InfoBlockAdvantage></InfoBlockAdvantage>
      </div>
    );
  }
  
  export default InfoBlock;