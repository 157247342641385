import React, { useState, useEffect } from "react";
const items = [
  {
    id: 1,
    title: "Система ценообразования",
    description:
      "Учитываем объемы, при заказе от 5 тонн предоставляем индивидуальные цены на весь сортамент",
  },
  {
    id: 2,
    title: "Доставка",
    description:
      "Работаем с надежными транспортными компаниями, такими как  «ЖелДорЭкспедиция», «Байкал Сервис», «Деловые Линии» и «ПЭК»",
  },
  {
    id: 3,
    title: "Удобство",
    description:
      "Возможная доставка на строительный объект, производство или в любое другое удобное для вас место",
  },
  {
    id: 4,
    title: "Индивидуальный подход",
    description:
      "Оказываем услуги по погрузке и разгрузке материалов, есть возможность поэтапной или разовой оплаты с НДС",
  },
];

function InfoBlockAdvantage() {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  const [hoveredItem, setHoveredItem] = useState(null);

  const handleMouseEnter = (id) => {
    if (!isMobile) {
      setHoveredItem(id);
    }
  };

  const handleMouseLeave = () => {
    if (!isMobile) {
      setHoveredItem(null);
    }
  };

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <div className="row my-5">
      {items.map((item) => (
        <div className="col-md-6 my-3 user-select-none" key={item.id}>
          <div
            className="card h-100 border-0 bg-light align-items-center"
            onMouseEnter={() => handleMouseEnter(item.id)}
            onMouseLeave={handleMouseLeave}
            style={{
              padding: isMobile ? "12px" : "3rem",
              scale: hoveredItem === item.id ? "1.05" : "1",
              transition: 0.3 + "s ease",
            }}
          >
            <img
              src="/check.png"
              className="card-img-top p-3 img-fluid rounded-circle bg-warning"
              alt={item.title}
              style={{ width: "100px" }}
            />
            <div className="card-body text-center">
              <h4 className="card-title">{item.title}</h4>
              <p className="card-text fs-5">{item.description}</p>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
}

export default InfoBlockAdvantage;
