import BannerInfo from "../componentBannerInfoForPages/BannerInfo";
import Breadcrumbs from "../componentBreadCrumps/Breadcrumbs";
import sortPrice from "../../price/testoviypricelist.xlsx";
import ConditionComp from "../componentCondition/Condition";
import SalesBlock from "../componentSales/salesBlock";
import InfoBlock from "../componentInfoBlock/InfoBlock";
import YandexMap from "../componentYandexMap/yandexMap";
import { Helmet } from "react-helmet";
const items = [
  { name: "Горячекатаный" },
  { name: "Оцинкованный" },
  { name: "Рифленый" },
  { name: "Холоднокатаный" },
  { name: "Просечно-вытяжной лист (ПВЛ)" },
  { name: "Профнастил" },
  { name: "Рулон холоднокатаный" },
  { name: "Штрипс" },
];


const ListTitle = "Листовой прокат оптом с доставкой";
const ListText =
  "Компания «ММК» предлагает широкий ассортимент листового проката для использования в различных отраслях промышленности. У нас вы найдете листы из различных марок стали, разных толщин и размеров, которые отвечают самым высоким стандартам качества.";
const ListText2 =
  "Одно из главных преимуществ нашей компании - возможность купить листовой металл недорого. Мы гарантируем своим клиентам конкурентоспособные цены, так как работаем напрямую с производителями и имеем минимальные затраты на посреднические услуги.";

function TrubniyProkat() {
  return (
    <div className="container-fluid p-0">
      <Helmet>
        <title>Листовой прокат</title>
      </Helmet>
      <BannerInfo
        img="backgroundImages/metalloprokatlistBG.webp"
        title="Листовой прокат"
      ></BannerInfo>
      <div className="container-fluid p-0 pb-5 bg-light">
        <div className="container">
          <Breadcrumbs></Breadcrumbs>
          <h2>Листовой прокат</h2>
          <div className="row">
            {items.map((item, index) => (
              <div className="col-md-3 col-6 my-2 user-select-none" key={index}>
                <div
                  className="card bg-secondary text-white"
                  style={{ height: "100px" }}
                >
                  <div className="card-body d-flex align-items-center justify-content-center">
                    <h5 className="card-title text-center">{item.name}</h5>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
        
      </div>
      <div className="text-center">
          {/* <a
            href={sortPrice}
            download="ТестПрайсЛистСпиздилСКакоготоСайта.xlsx"
          >
            <button className="btn btn-warning">Скачать прайс-лист</button>
          </a> */}
        </div>
      <ConditionComp imgSrc="sortImg.webp"></ConditionComp>
        <SalesBlock></SalesBlock>
        <InfoBlock title={ListTitle} img="/sortImg.webp" text={ListText} text2={ListText2}></InfoBlock>
        <YandexMap></YandexMap>
    </div>
  );
}

export default TrubniyProkat;
