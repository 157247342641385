import "../componentYandexMap/mapStyle.css";
import React, { useState, useEffect } from "react";

function YandexMap() {
    const [isOverlayVisible, setOverlayVisibility] = useState(true);

    const handleOverlayClick = () => {
      setOverlayVisibility(false);
    };
  
    const handleMouseLeave = () => {
      setOverlayVisibility(true);
    };


  return (
    <div
      className="mt-5"
      style={{ position: 'relative', width: '100%', height: '400px' }}
      onMouseLeave={handleMouseLeave}
    >
      <div
        className={`overlay ${isOverlayVisible ? '' : 'hidden'}`}
        onClick={handleOverlayClick}
      >
        <h3 className="text-center">Нажмите, чтобы активировать карту</h3>
      </div>
      <iframe
        src="https://yandex.ru/map-widget/v1/?um=constructor%3A305922a61c68dd9c130e4067a0ef0f5bcc171ad811fbf57fd8f04574ff337a38&amp;source=constructor"
        width="100%"
        height="100%"
        frameBorder="0"
      ></iframe>
    </div>
  );
}

export default YandexMap;
