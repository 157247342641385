import React, { useState, useEffect } from 'react';
import PropTypes from "prop-types";
import "./conditionstyle.css";

const conditions = [
  {
    text: "В регионы заказы отправляются транспортными компаниями",
  },
  {
    text: "Доставка в течение 5 дней после поступления предоплаты при условии наличия нужного объема продукции на складе",
  },
  {
    text: "Доставка возможна в любое удобное для вас место: склад, строительный объект, производство",
  },
  {
    text: "При необходимости предоставляется помощь в погрузке и разгрузке материалов",
  },
  {
    text: "График доставки каждый день с 8:00 до 19:00 без праздников и выходных",
  },
];

const ConditionComp = ({ imgSrc }) => {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  return (
    <div className="container my-5">
      <h2 className="my-5">Условия отгрузки и доставки</h2>
      <div className="row">
        <div className="col-md-6 d-flex flex-column gap-3">
          {conditions.map((condition, index) => (
            <div
              key={index}
              className="d-flex p-3 bg-light bg-gradient condition align-items-center"
            >
              <img src="/arrow__right.png" width="50px" alt="Стрелка вправо" />
              <p className="ms-5">{condition.text}</p>
            </div>
          ))}
        </div>
        <div className="col-md-6" style={{display: isMobile ? "none" : "block"}}>
          <img
            src={imgSrc}
            alt="Описание изображения"
            className="img-fluid h-100 w-100"
          />
        </div>
      </div>
    </div>
  );
};

ConditionComp.propTypes = {
  imgSrc: PropTypes.string.isRequired,
};
export default ConditionComp;
