import './cooperationTerms.css';

import first__pic from "./sc-contrats.webp";
import second__pic from "./iso9001.jpg";
import third__pic from "./deliveryFromFactory.webp";
import fourht__pic from "./deliveryTrack.jpg";

function CooperationTerms() {
    return (
      <div className='container py-5' id='terms__of__cooperation'>
        <div className='row'>
            <div className='col-xxl-4 col-lg-6 col-md-6 col-xs-6 my-5'>
                <h2>Условия сотрудничества</h2>
                <div className='left__block d-flex flex-column gap-5'>

                    <div className='d-flex align-items-center'>
                        <div className='rounded__image'></div>
                        <p>Минимальный заказ - 1 тонна</p>
                    </div>

                    <div className='d-flex align-items-center'>
                        <div className='rounded__image'></div>
                        <p>Отправка в регионы через ТК</p>
                    </div>

                    <div className='d-flex align-items-center'>
                        <div className='rounded__image'></div>
                        <p>Отгрузка и доставка на объект</p>
                    </div>

                    <div className='d-flex align-items-center'>
                        <div className='rounded__image'></div>
                        <p>Договоры на комплексное снабжение</p>
                    </div>
                    
                </div>
            </div>
            <div className='col-xxl-8 col-lg-6 col-md-6 col-xs-6 my-5'>
                <h2>Чем отличается наш подход</h2>

                <div className='right__block d-flex justify-content-center row'>
                    <div className="card card__terms col-sm-6">
                        <img src={first__pic} loading='lazy' alt=""/>
                        {/* <div className="card-body text-center"> */}
                            <h5 className="card-title text-center pt-2">Заключаем договоры на комплексное снабжение</h5>
                            <hr className='m-0 text-warning mt-auto'></hr>
                        {/* </div> */}
                    </div>
                    <div className="card card__terms col-sm-6">
                        <img src={second__pic} loading='lazy' alt="..."/>
                        {/* <div className="card-body text-center"> */}
                            <h5 className="card-title text-center pt-2">Имеются необходимые сертификаты на продукцию</h5>
                            <hr className='m-0 text-warning mt-auto'></hr>
                        {/* </div> */}
                    </div>
                    <div className="card card__terms col-sm-6 mt-auto">
                        <img src={third__pic} loading='lazy' alt="..."/>
                        {/* <div className="card-body text-center"> */}
                            <h5 className="card-title text-center pt-2">Поставляем продукцию напрямую с заводов</h5>
                            <hr className='m-0 text-warning mt-auto'></hr>
                        {/* </div> */}
                    </div>
                    <div className="card card__terms col-sm-6 col-12 mt-auto">
                        <img src={fourht__pic} loading='lazy' alt="..."/>
                        {/* <div className="card-body text-center"> */}
                            <h5 className="card-title text-center pt-2">Сопровождение сделки от заказа до отгрузки</h5>
                            <hr className='m-0 text-warning mt-auto'></hr>
                        {/* </div> */}
                    </div>
                </div>
            </div>
        </div>
      </div>
    );
  }
  
  export default CooperationTerms;
  