// App.js
import React from 'react';
import { Routes, Route } from 'react-router-dom';
import Navbar from './components/a_navbar/Navbar';
import BackToTop from './components/utility/BackToTop';
import ScrollToTop from './components/utility/ScrollToTop';
import HomePage from './components/pages/HomePage';
import SortovoiProkat from './components/pages/SortovoiProkat';
import TrubniyProkat from './components/pages/TrubniyProkat';
import ListovoiProkat from './components/pages/ListovoiProkat';
import NerjaveiushiyProkat from './components/pages/NerjaveiushiyProkat';
import Ferrosplavi from './components/pages/Ferrosplavi';
import AboutPage from './components/pages/AboutPage';
import AllProducts from './components/pages/AllProducts';
import ContactsPage from './components/pages/ContactsPage';
import LiftingEquipment from './components/pages/LiftingEquipment';
import NotFoundPage from './components/pages/NotFoundPage'; // Страница 404
import Footer from './components/z_footer/Footer';
import "./App.css"

// Объект с маршрутами и их метками на русском языке


function App() {
  return (
    <>
      <Navbar />
      <ScrollToTop /> {/* Перемещён за пределы Routes */}
      <div>
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/about" element={<AboutPage />} />
          <Route path="/SortovoiProkat" element={<SortovoiProkat />} />
          <Route path="/TrubniyProkat" element={<TrubniyProkat />} />
          <Route path="/ListovoiProkat" element={<ListovoiProkat />} />
          <Route path="/NerjaveiushiyProkat" element={<NerjaveiushiyProkat />} />
          <Route path="/Ferrosplavi" element={<Ferrosplavi />} />
          <Route path="/LiftingEquipment" element={<LiftingEquipment />} />
          <Route path="/AllProducts" element={<AllProducts />} />
          <Route path="/contacts" element={<ContactsPage />} />
          <Route path="*" element={<NotFoundPage />} /> {/* Маршрут для несуществующих страниц */}
        </Routes>
      </div>
      <Footer />
      <BackToTop /> {/* Кнопка "Наверх" */}
    </>
  );
}

export default App;
