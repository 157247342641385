import Breadcrumbs from "../componentBreadCrumps/Breadcrumbs";
import YandexMap from "../componentYandexMap/yandexMap";
import "./pages.css";

import adress from "../../images/contacts/gps.png";
import number from "../../images/contacts/phone-call.png";
import email from "../../images/contacts/mail.png";
import requisites from "../../images/contacts/importance.png";
import schedule from "../../images/contacts/calendar.png"
import { Helmet } from "react-helmet";

function ContactsPage() {
  return (
    <div className="container-fluid p-0">
      <Helmet>
        <title>Наши контакты</title>
      </Helmet>
      <div id="contacts__background"></div>
      <div className="container">
        <Breadcrumbs></Breadcrumbs>
        <h2>Контакты</h2>
        <div className="row my-5" id="contact__info__block">
          <div className="col-sm-6 d-flex my-3">
            <div className="left__circle__of__contact__block">
              <img src={adress} className="img-fluid p-1" alt="" />
            </div>
            <div className="flex-column w-100 ms-4">
              <h4>Адрес</h4>
              <hr className="m-0"></hr>
              <p className="fs-5">Свердловский тракт, д.5, стр. 2, оф. 20</p>
            </div>
          </div>

          <div className="col-sm-6 d-flex my-3">
            <div className="left__circle__of__contact__block">
              <img src={requisites} className="img-fluid p-3" alt="" />
            </div>
            <div className="flex-column w-100 ms-4">
              <h4>Реквизиты</h4>
              <hr className="m-0"></hr>
              <p className="fs-6 m-0">ИНН 7448251296</p>
              <p className="fs-6 m-0">ОГРН 1237400016281</p>
              <p className="fs-6 m-0">БИК 046577964</p>
            </div>
          </div>

          <div className="col-sm-6 d-flex my-3">
            <div className="left__circle__of__contact__block">
              <img src={number} className="img-fluid p-3"  alt="" />
            </div>
            <div className="flex-column w-100 ms-4">
              <h4>Телефон</h4>
              <hr className="m-0"></hr>
              <p className="fs-5 m-0">
                <a
                  href="tel:+799070979"
                  className="text-decoration-none text-warning"
                >
                  +7 (932) 430-07-00
                </a>
              </p>
              <p className="fs-5 m-0">
                <a
                  href="tel:+799070979"
                  className="text-decoration-none text-warning"
                >
                  +7 (919) 123-32-52
                </a>
              </p>
            </div>
          </div>

          <div className="col-sm-6 d-flex my-3">
            <div className="left__circle__of__contact__block">
              <img src={schedule} className="img-fluid p-3" alt="" />
            </div>
            <div className="flex-column w-100 ms-4">
              <h4>График работы</h4>
              <hr className="m-0"></hr>
              <p className="fs-5">Пн-Сб: с 8:00 до 20:00</p>
            </div>
          </div>

          <div className="col-sm-6 d-flex my-3">
            <div className="left__circle__of__contact__block">
              <img src={email} className="img-fluid p-3" alt="" />
            </div>
            <div className="flex-column w-100 ms-4">
              <h4>E-mail</h4>
              <hr className="m-0"></hr>
              <p className="fs-5">
                <a
                  href="mailto:mmetgk@list.ru"
                  className="text-decoration-none text-warning"
                >
                  mmetgk@list.ru
                </a>
              </p>
            </div>
          </div>
        </div>
      </div>
      <YandexMap></YandexMap>
    </div>
  );
}

export default ContactsPage;
