import React, { useState, useEffect } from 'react';
import './bgContainerBlock.css';
import SendMailBTN from '../sendMailBTN/SendMailBTN';
import imgOfBannerBlockHigh from './background-image.webp';
import imgOfBannerBlockLow from './low-res-background-image.webp';
import lowResBannerImage from './low-res-secondary-banner-image.webp';
import BannerImage from './secondary-banner-image.webp';

function BackgroundBanner() {
  // Состояния для фонового изображения
  const [imgSrc, setImgSrc] = useState(imgOfBannerBlockLow);
  const [loading, setLoading] = useState(true);

  // Состояния для вторичного изображения (circle__block)
  const [secondaryImgSrc, setSecondaryImgSrc] = useState(lowResBannerImage);
  const [secondaryLoading, setSecondaryLoading] = useState(true);

  useEffect(() => {
    // Функция для загрузки изображения
    const loadImage = (src, setSrc, setLoad) => {
      const img = new Image();
      img.src = src;
      img.onload = () => {
        setSrc(src);
        setLoad(false);
        console.log(`Изображение загружено: ${src}`);
      };
      img.onerror = () => {
        console.error(`Не удалось загрузить изображение: ${src}`);
        setLoad(false);
      };
    };

    // Начинаем загрузку обоих изображений
    loadImage(imgOfBannerBlockHigh, setImgSrc, setLoading);
    loadImage(BannerImage, setSecondaryImgSrc, setSecondaryLoading);
  }, []);

  return (
    <div 
      className="container-fluid banner__container mb-5" 
      style={{ 
        padding: "0", 
        backgroundImage: `url(${imgSrc})`,
        transition: "background-image 0.5s ease-in-out",
        backgroundSize: "cover",
        backgroundPosition: "center",
        minHeight: "700px",
        position: "relative"
      }}
    >
      <div className="container pt-5">
        <div className="row">
          <div className="col-md-6" style={{ margin: "auto 0" }}>
            <h1>Оптовые поставки металлопроката и ферросплавов</h1>
            <p>Широкий ассортимент изделий различной формы и размера из высококачественных материалов</p>
            <SendMailBTN />
          </div>
          <div className="col-md-6">
            <div className="circle__block">
              <img 
                src={secondaryImgSrc} 
                className="img__of__banner__block" 
                alt="Декоративный баннер"
                onLoad={() => console.log('Декоративный баннер загружен:', secondaryImgSrc)}
                style={{
                  transition: "opacity 0.3s ease-in-out",
                  opacity: secondaryLoading ? 0.3 : 1,
                }}
              />
            </div>
          </div>          
        </div>
      </div>
    </div>
  );
}

export default BackgroundBanner;
