import OurProducts from "../componentOurProducts/OurProducts";
import Breadcrumbs from "../componentBreadCrumps/Breadcrumbs";
import YandexMap from "../componentYandexMap/yandexMap";
import { Helmet } from "react-helmet";
function VsaProdukcia() {
    return (
      <div className="container-fluid p-0">
        <Helmet>
          <title>Вся продукция</title>
        </Helmet>
        <div id="all__products__background"></div>
        <div className="container">
          <Breadcrumbs></Breadcrumbs>
        </div>
        <OurProducts></OurProducts>
        <YandexMap></YandexMap>
      </div>
    );
  };

export default VsaProdukcia;