import "./preFooter.css";
import preFooterIMG from "./pre__footer__image.png";
import { NavLink } from "react-router-dom";

function PreFooter() {
  return (
    <div className="container-fluid pt-5 my-5" id="pre__footer">
      <div className="container">
        <div className="row">
          <div className="col-md-7">
            <h2 className="fw-bold">
              «ММGК» - ваш надежный поставщик качественного металлопроката и
              ферросплавов
            </h2>
            <p className="mt-5">
              ООО «ММGК» предлагает купить металлопрокат оптом по выгодной цене
              от производителя. У нас вы найдете широкий ассортимент
              металлопроката: трубы, листы, сортовой прокат, нержавеющую сталь и
              многое другое.
            </p>
            <p>
              Мы работаем напрямую с производителями металлопроката и предлагаем
              выгодные условия для оптовых покупателей. Заключаем договоры на
              комплексное снабжение, минимальный заказ составляет всего 1000 кг.
              С каждой партией поставляем сертификат на продукцию, по запросу
              процесс сделки сопровождает экспедитор: от заказа до отгрузки на
              объекте.
            </p>
            <p>
              Компания имеет большой опыт работы на рынке металлопроката,
              поэтому гарантирует качество продукции и быструю обработку
              заказов.
            </p>
            <NavLink to="/about" className="btn btn-warning">Читать подробнее</NavLink>
          </div>

          <div className="col-md-5">
            <img src={preFooterIMG} loading="lazy" width={100 + "%"}></img>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PreFooter;
