import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/autoplay";
import { Autoplay, Pagination, Navigation } from "swiper/modules";
import "./carousel.css";

import MMK from "../../images/dealers/MMK.png";
import AOOctober from "../../images/dealers/AOOctober.png";
import Evraz from "../../images/dealers/Evraz.png";
import TulaSteel from "../../images/dealers/TulaSteel.png";
import MKM from "../../images/dealers/MKM.png";
import Mechel from "../../images/dealers/Mechel.png";
import KTZ from "../../images/dealers/KTZ.png";
import ZSK from "../../images/dealers/ZSK.png";

function CarouselComponent() {
  return (
    <Swiper
      spaceBetween={30}
      slidesPerView={1}
    //   centeredSlides={true}
      loop={true}
      autoplay={{
        delay: 2590,
        disableOnInteraction: true,
      }}
      pagination={{
        clickable: true,
      }}
      breakpoints={{
        640: {
          slidesPerView: 1,
          spaceBetween: 20,
        },
        768: {
          slidesPerView: 3,
          spaceBetween: 40,
        },
        1200: {
          slidesPerView: 5,
          spaceBetween: 50,
        },
      }}
      navigation={true}
      modules={[Autoplay, Pagination, Navigation]}
      className="mySwiper"
    >
      <SwiperSlide>
        <img src={MMK} loading="lazy" alt="MMK" />
      </SwiperSlide>
      <SwiperSlide>
        <img src={AOOctober} loading="lazy" alt="Красный Октябрь" />
      </SwiperSlide>
      <SwiperSlide>
        <img src={Evraz} loading="lazy" height={"auto"} alt="Евраз" />
      </SwiperSlide>
      <SwiperSlide>
        <img src={TulaSteel} loading="lazy" alt="Тула-Сталь" />
      </SwiperSlide>
      <SwiperSlide>
        <img src={MKM} loading="lazy" alt="Металлокомплект М" />
      </SwiperSlide>
      <SwiperSlide>
        <img src={Mechel} loading="lazy" alt="Мечел" />
      </SwiperSlide>
      <SwiperSlide>
        <img src={KTZ} loading="lazy" alt="Кировский Трубный завод" />
      </SwiperSlide>
      <SwiperSlide>
        <img src={ZSK} loading="lazy" alt="Завод Строн-Комплект" />
      </SwiperSlide>
    </Swiper>
  );
}

export default CarouselComponent;
