import React from 'react';
import './Footer.css'; // Подключаем файл стилей для дополнительных стилизаций
import { NavLink } from 'react-router-dom';
import Logo from "../../images/logos/logo_new_negate.webp";
import Telegram from "../../images/logos/TelegramLogo.png";
import WhatsApp from "../../images/logos/WhatsAppLogo.png";

function Footer() {
  return (
    <footer className="footer py-5 mt-auto">
      <div className="container">
        <div className="row">
          <div className="col-md-3 mb-3 mb-md-0 text-center text-md-start">
            <NavLink to="/">
            <img src={Logo} alt="Логотип" className="img-fluid mb-3" width="150" />
            </NavLink>
          </div>
          <div className="col-md-3 mb-3 mb-md-0">
            <h5>Быстрые ссылки</h5>
            <ul className="list-unstyled">
              <li><NavLink to="/">Главная</NavLink></li>
              <li><NavLink to="/about">О компании</NavLink></li>
              <li><NavLink to="/VsaProdukcia">Продукция</NavLink></li>
              <li><NavLink to="/contacts">Контакты</NavLink></li>
            </ul>
          </div>

          <div className="col-md-3 mb-3 mb-md-0">
          <h5>Продукция</h5>
            <ul className="list-unstyled">
              <li><NavLink to="/SortovoiProkat">Сортовой прокат</NavLink></li>
              <li><NavLink to="/TrubniyProkat" >Трубный прокат</NavLink></li>
              <li><NavLink to="/ListovoiProkat">Листовой прокат</NavLink></li>
              <li><NavLink to="/NerjsaveiushiyProkat">Нержавеющий прокат</NavLink></li> 
              {/* Помменять путь у нержавейки когда инфа будет */}
              <li><NavLink to="/Ferrosplavi">Ферросплавы</NavLink></li>
              <li><NavLink to="/LiftingEquipment">Грузоподъемное оборудование</NavLink></li>
            </ul>
          </div>

          <div className="col-md-3 text-center text-md-start">
          <h5>Контакты</h5>
            <p><a href="tel:79324300700" className="text-decoration-none fs-5 number">+7 (932) 430-07-00</a></p>
            <p><a href="tel:79191233252" className="text-decoration-none fs-5 number">+7 (919) 123-32-52</a></p>
            <p><a target="_blank" rel="noreferrer" className="geolocation" href="https://yandex.ru/maps/-/CHAMVD60">г.Челябинск, Свердловский тракт, 5/2</a></p>
            <div className="d-flex justify-content-center justify-content-md-start gap-2">
              <a href="https://telegram.org" target="_blank" rel="noopener noreferrer">
                <img src={Telegram} alt="Telegram" className="social-icon"/>
              </a>
              <a href="https://www.whatsapp.com" target="_blank" rel="noopener noreferrer">
                <img src={WhatsApp} alt="WhatsApp" className="social-icon"/>
              </a>
            </div>
          </div>
        </div>

        <hr className='my-5' />
        <p className='text-secondary text-center'>©2024 «ММGК» - все права защищены</p>
      </div>
    </footer>
  );
}

export default Footer;
