import React from 'react';
import { NavLink, useLocation } from 'react-router-dom';

function Breadcrumbs() {

    const routes = {
        "/": "Главная",
        "/about": "О компании",
        "/sortovoiProkat": "Сортовой Прокат",
        "/TrubniyProkat": "Трубный Прокат",
        "/ListovoiProkat": "Листовой Прокат",
        "/NerjaveiushiyProkat": "Нержавеющий Прокат",
        "/Ferrosplavi": "Ферросплавы",
        "/LiftingEquipment": "Грузоподъемное оборудование",
        "/AllProducts": "Наша продукция",
        "/contacts": "Контакты"
      };

    const location = useLocation();
    const pathnames = location.pathname.split('/').filter(x => x);
    
    const breadcrumbItems = pathnames.map((name, index) => {
      const routeTo = `/${pathnames.slice(0, index + 1).join('/')}`;
      const isLast = index === pathnames.length - 1;
      const label = routes[routeTo] || name;
      return isLast ? (
        <span key={name}> / {label}</span>
      ) : (
        <span key={name}> / <NavLink to={routeTo}>{label}</NavLink></span>
      );
    });
  
    return (
      <div className="py-3">
        <NavLink to="/" className="text-warning">Главная</NavLink>
        {breadcrumbItems}
      </div>
    );
  }

export default Breadcrumbs;