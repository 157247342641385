// formHandler.js

export async function handleFormData(formData, captchaToken) {
    const data = new FormData();
    data.append('fio', formData.fio);
    data.append('phone', formData.phone);
    data.append('message', formData.message);
    if (formData.file) {
        console.log('Добавляем файл:', formData.file.name);
        data.append('file', formData.file);
    } else {
        console.log('Файл не выбран.');
    }

    // Добавляем токен reCAPTCHA
    if (captchaToken) {
        data.append('token', captchaToken);
    }

    try {
        console.log("test")
        const response = await fetch('../static/server/send-email.php', { // Обновленный URL
            method: 'POST',
            body: data,
        });
        const result = await response.json();
        if (result.success) {
            console.log('Письмо отправлено:', result.message);
            // Дополнительная обработка успешной отправки
            return { success: true, message: result.message };
        } else {
            console.error('Ошибка отправки:', result.error);
            // Обработка ошибок
            return { success: false, message: result.error || 'Ошибка при отправке формы.' };
        }
    } catch (error) {
        console.error('Ошибка сети:', error);
        return { success: false, message: 'Ошибка сети. Пожалуйста, попробуйте позже.' };
    }
}
