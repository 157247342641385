// BannerInfo.jsx
import React from "react";
import PropTypes from "prop-types";
import SendMailBTN from "../sendMailBTN/SendMailBTN";
const defaultTextProp =
  "Укажите вид металлолома и нужный объем, мы перезвоним и обсудим условия оплаты и поставки";
const BannerInfo = ({ img, title, text = defaultTextProp, showButton }) => {
  const bannerStyle = {
    position: "relative",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "whitesmoke",
    minHeight: "600px",
    width: "100%",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    backgroundPosition: "0 30%",
    backgroundImage: `url(${img})`,
  };

  const h1Style = {
    zIndex: 100,
    color: "white",
    fontWeight: 700,
    fontSize: "4rem",
  };

  const paragraphStyle = {
    color: "white",
    fontSize: "1.25rem",
    width: "100%",
    margin: "1rem 0",
  };

  return (
    <div className="bannerInfo" style={bannerStyle}>
      <div className="container">
        <h1 style={h1Style}>{title}</h1>
        <p style={paragraphStyle}>{text}</p>
        {showButton && (
          <SendMailBTN></SendMailBTN>
        )}
      </div>
    </div>
  );
};
BannerInfo.propTypes = {
  img: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  showButton: PropTypes.bool,
};
BannerInfo.defaultProps = {
  showButton: true,
};

export default BannerInfo;
