import BannerInfo from "../componentBannerInfoForPages/BannerInfo";
import Breadcrumbs from "../componentBreadCrumps/Breadcrumbs";
import sortPrice from "../../price/testoviypricelist.xlsx";
import ConditionComp from "../componentCondition/Condition";
import SalesBlock from "../componentSales/salesBlock";
import InfoBlock from "../componentInfoBlock/InfoBlock";
import YandexMap from "../componentYandexMap/yandexMap";
import React, { useState, useEffect } from 'react';
import { Helmet } from "react-helmet";
const items = [
  { name: "Мачтовый подъемник" },
  { name: "Коленчатый подъемник" },
  { name: "Лебедки" },
  { name: "Штабелеры" },
  { name: "Краны гидравлические" },
  { name: "Домкраты подкатные" },
  { name: "Таль электрическая" },
  { name: "Балка подвесная" },
];

const ListTitle = "Грузоподъемное оборудование с доставкой";
const ListText =
  "Компания «ММГК» предлагает широкий ассортимент грузоподъемного оборудования для использования в различных отраслях промышленности.";
const ListText2 =
  "Одно из главных преимуществ нашей компании - возможность купить грузоподъемное оборудование недорого. Мы гарантируем своим клиентам конкурентоспособные цены, так как работаем напрямую с производителями и имеем минимальные затраты на посреднические услуги.";

function LiftingEquipment() {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  return (
    <div className="container-fluid p-0">
      <Helmet>
        <title>Грузоподъемное оборудование</title>
      </Helmet>
      <BannerInfo
        img="backgroundImages/liftingeqBG.webp"
        title="Грузоподъемное оборудование"
      ></BannerInfo>
      <div className="container-fluid p-0 pb-5 bg-light">
        <div className="container">
          <Breadcrumbs></Breadcrumbs>
          <h2>Грузоподъемное оборудование</h2>
          <div className="row">
            {items.map((item, index) => (
              <div className="col-md-3 col-6 my-2 user-select-none" key={index}>
                <div
                  className="card bg-secondary text-white"
                  style={{ height: "100px" }}
                >
                  <div className="card-body d-flex align-items-center justify-content-center">
                    <h5 className="card-title text-center">{item.name}</h5>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
      <div className="text-center">
        {/* <a href={sortPrice} download="ТестПрайсЛистСпиздилСКакоготоСайта.xlsx">
          <button className="btn btn-warning">Скачать прайс-лист</button>
        </a> */}
      </div>
      <ConditionComp imgSrc="sortImg.webp"></ConditionComp>
      <SalesBlock></SalesBlock>
      <InfoBlock
        title={ListTitle}
        img="/sortImg.webp"
        text={ListText}
        text2={ListText2}
      ></InfoBlock>
      <YandexMap></YandexMap>
    </div>
  );
}

export default LiftingEquipment;
