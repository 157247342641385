import React, { useState } from "react";
import { Modal, Button, Form, Spinner } from "react-bootstrap";
import ReCAPTCHA from "react-google-recaptcha";
import InputMask from "react-input-mask";
import { handleFormData } from "./formHandler"; // Оставляем импорт без изменений

const defaultTextBTN = "Оставить заявку";
const SendMailBTN = ({ textBTN = defaultTextBTN }) => {
  const [show, setShow] = useState(false);
  const [formData, setFormData] = useState({
    fio: "",
    phone: "",
    message: "",
    file: null,
  });
  const [captchaValue, setCaptchaValue] = useState(null);
  const [error, setError] = useState(""); // Состояние для ошибок
  const [success, setSuccess] = useState(false); // Состояние для успешной отправки
  const [loading, setLoading] = useState(false);
  const handleClose = () => {
    setShow(false);
    setError("");
    setSuccess(false);
    setFormData({
      fio: "",
      phone: "",
      message: "",
      file: null,
    });
    setCaptchaValue(null);
  };
  const handleShow = () => setShow(true);

  const handleChange = (e) => {
    const { name, value, files } = e.target;
    if (name === "file") {
      setFormData((prevData) => ({
        ...prevData,
        file: files[0],
      }));
    } else {
      setFormData((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    }
  };

  const handleCaptchaChange = (value) => {
    setCaptchaValue(value);
    if (value) {
      setError("");
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Сброс предыдущих ошибок, успехов и установка состояния загрузки
    setError("");
    setSuccess(false);
    setLoading(true);

    if (!captchaValue) {
      setLoading(false); // Отключаем загрузку, так как есть ошибка
      setError("Пожалуйста, пройдите проверку reCAPTCHA.");
      return;
    }

    try {
      // Вызов handleFormData и передача FormData и токена captcha
      const result = await handleFormData(formData, captchaValue);

      if (result.success) {
        setSuccess(true);
        console.log("[DEV] Письмо отправлено");
        handleClose();
      } else {
        setError(result.message || "Ошибка при отправке формы.");
      }
    } catch (err) {
      console.error("Ошибка при отправке формы:", err);
      setError("Серверная ошибка. Пожалуйста, попробуйте позже.");
    } finally {
      setLoading(false); // Отключаем загрузку после завершения
    }
  };

  return (
    <div className="container mt-5 p-0">
      <Button variant="warning" onClick={handleShow}>
        {textBTN}
      </Button>

      <Modal show={show} variant="dark" onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Заполните форму</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleSubmit}>
            <Form.Group controlId="formFIO" className="mb-3">
              <Form.Label>ФИО</Form.Label>
              <Form.Control
                type="text"
                placeholder="Введите ФИО"
                name="fio"
                value={formData.fio}
                onChange={handleChange}
                required
              />
            </Form.Group>

            <Form.Group controlId="formPhone" className="mb-3">
              <Form.Label>Номер телефона</Form.Label>
              <InputMask
                mask="+7 (999) 999-99-99"
                value={formData.phone}
                onChange={handleChange}
                maskChar="_"
              >
                {(inputProps) => (
                  <Form.Control
                    {...inputProps}
                    type="tel"
                    placeholder="+7 (___) ___-__-__"
                    name="phone"
                    required
                  />
                )}
              </InputMask>
            </Form.Group>

            <Form.Group controlId="formMessage" className="mb-3">
              <Form.Label>Сообщение</Form.Label>
              <Form.Control
                as="textarea"
                rows={3}
                maxLength={500}
                placeholder="Введите ваше сообщение (до 500 символов)"
                name="message"
                value={formData.message}
                onChange={handleChange}
                required
              />
              <Form.Text muted>
                Осталось символов: {500 - formData.message.length}
              </Form.Text>
            </Form.Group>

            <Form.Group controlId="formFile" className="mb-3">
              <Form.Label>Прикрепить файл</Form.Label>
              <Form.Control type="file" name="file" onChange={handleChange} />
            </Form.Group>

            <Form.Group className="mb-3">
              <ReCAPTCHA
                sitekey="6LfWyX8qAAAAAAuvN0p1W7uXmMl0CLWzpM28rJoO" // Замените на ваш Site Key
                onChange={handleCaptchaChange}
              />
              {error && <p style={{ color: "red" }}>{error}</p>}
            </Form.Group>

            {success && (
              <p style={{ color: "green" }}>Форма успешно отправлена!</p>
            )}

            <Button
              variant="warning text-center w-100"
              type="submit"
              onClick={handleSubmit}
              disabled={loading} // Кнопка неактивна, если идет загрузка
            >
              {loading ? (
                <>
                  {/* Если идет загрузка, показываем Spinner */}
                  <Spinner
                    as="span"
                    animation="border"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                    className="me-2"
                  />
                  Загрузка...
                </>
              ) : (
                "Отправить"
              )}
            </Button>
          </Form>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default SendMailBTN;
